import { Collapse, Divider } from 'antd';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import values from 'lodash/values';

import { Smart } from './Smart';

import './Goals.css';

type GoalTypes = {
  achievable: string, 
  category: string,
  description: string,
  measurable: string,
  relevant: string,
  specific: string,
  timeBound: string,
};

type ComponentProps = {
  data: GoalTypes[];
};

type CollapseItemsTypes = {
  key: GoalTypes['description'],
  label: GoalTypes['description'],
  children: React.ReactNode,
};

export const Goals = ({
  data,
}: ComponentProps) => {
  const sortedData = values(groupBy(data, 'category'));

  const renderGoals = () => {
    let renderCategory = false;
    let category = '';

    return (
      <>
        {sortedData.map((item: GoalTypes[]) => {
          if (item[0].category !== category) {
            category = item[0].category;
            renderCategory = true;
          }

          let collapseItems:CollapseItemsTypes[] = [];

          forEach(item, (answer: GoalTypes) => {
            collapseItems.push({
              key: answer.description,
              label: answer.description,
              children: <Smart 
                specific={answer.specific}
                measurable={answer.measurable}
                achievable={answer.achievable}
                relevant={answer.relevant}
                timeBound={answer.timeBound}
              />
            })
          });

          return (
            <>
              {renderCategory && <Divider orientation="left">{category}</Divider>}
              <Collapse
                items={collapseItems}
              />
            </>
          );
        })}
      </>
    );
  };

  return (
    <div id="goals" className="goals">
      <div className="goals__header">
        <h2>Your Personal Goals</h2>
      </div>

      <div className="goals__container">
        {renderGoals()}
      </div>
    </div>
  );
};