import './Header.css';

export const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={require("../../assets/logo.png")} alt="PlanMyLife" />
        </a>
      </div>
    </header>
  )
};