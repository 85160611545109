import './Category.css';

type ComponentProps = {
  title: string,
};

export const Category = ({ title }: ComponentProps) => {
  return (
    <h3 className="category__title">{title}</h3>
  )
}