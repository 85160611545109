import './AboutMe.css';

export const AboutMe = () => {
  return (
    <div className="aboutme">
      <div className='aboutme__container'>
        <img className="aboutme__image" src="https://images.squarespace-cdn.com/content/v1/656f19d62fa4516887a764d6/1701779952586-A2SW9IB0710FW8BE0CUO/steve.png?format=750w" />
        <h2>About Me</h2>
        <p>Hey there! My name is Steve and I'm a creator who's spent much of my life going with the flow, without setting clear personal goals.</p>
        <p>That all changed a few years back when I discovered the power of detailed life planning and goal setting – it's been a game changer!</p>
        <p>I'm currently refining a personal planner, born from my own weekly planning routine with a strong emphasis on goal setting. It's been my secret weapon, and I'm excited to share it with the world later this year.</p>
        <p>For now, check out this quick tool I built to kickstart your journey towards effective goal-setting for the year. Dive in and see where it can take you!</p>
      </div>
    </div>
  )
};