import { Tag } from 'antd';

import './Question.css';

import {
  DataType,
} from '../data';
import {
  UpdateTypes,
} from '../index';

type ComponentProps = {
  data: DataType,
  selectedAnswers: string[],
  categoryIndex: number,
  questionIndex: number,
  selectedCategoryIndex: number,
  selectedQuestionIndex: number,
  update: (values: UpdateTypes) => void,
};

const replaceAsterisksWithBold = (text:string) => text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

export const Question = ({
  data,
  selectedAnswers,
  categoryIndex,
  questionIndex,
  selectedCategoryIndex,
  selectedQuestionIndex,
  update,
}: ComponentProps) => {
  const { CheckableTag } = Tag;

  const handleChange = async (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedAnswers, tag]
      : selectedAnswers.filter((t) => t !== tag);

    await update({
      selectedAnswers: nextSelectedTags,
      categoryIndex,
      questionIndex,
    });
  };

  if (selectedCategoryIndex > categoryIndex ||
    selectedQuestionIndex > questionIndex) {
    return (
      <div className="question">
        <p className="question__title">{data.title}</p>
        <div className="question__tags">
          {selectedAnswers.map((tag) => (
            <CheckableTag
              key={tag}
              checked={true}
            >
              <span dangerouslySetInnerHTML={{ __html: replaceAsterisksWithBold(tag)}} />
            </CheckableTag>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="question">
      <p className="question__title">{data.title}</p>
      <div className="question__tags">
        {data.answers.map((tag) => (
          <CheckableTag
            key={tag}
            checked={selectedAnswers.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            <span dangerouslySetInnerHTML={{ __html: replaceAsterisksWithBold(tag)}} />
          </CheckableTag>
        ))}
      </div>
    </div>
  )
}