// @ts-nocheck
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Header } from './components/Header';
import { Questions } from './components/Questions';
import { AboutMe } from './components/AboutMe';

import './App.css';

const App = () => {
  useEffect(() => {
    const user = 'steve';
    const domain = 'planmylife.app';
    var elem = document.getElementById('email-link');
    elem.href = 'mailto:' + user + '@' + domain;
    elem.textContent = user + '@' + domain;
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Personal Goals for 2024</title>
        <link rel="icon" type="image/png" sizes="32x32" href="https://static.planmylife.app/icons/planmylife-512x512v2.png" />
        <meta name="viewport" content="width=device-width,user-scalable=0,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0" />
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap" rel="stylesheet" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content="https://personalgoalsgenerator.planmylife.app/"
        />
        <meta property="og:title" content="Personal Goals for 2024" />
        <meta
          property="og:description"
          content="AI-powered tool to create personal goals. Smart, strategic, and simple to use. Elevate your productivity and start achieving today!"
        />
        <meta
          property="og:image"
          content="https://static.planmylife.app/assets/personalgoalsgenerator/social.png"
        />
        <meta property="og:image:width" content="823" />
        <meta property="og:image:height" content="434" />

        {/* Twitter */}
        <meta
            property="twitter:card"
            content="summary_large_image"
        />
        <meta
            property="twitter:url"
            content="https://personalgoalsgenerator.planmylife.app/"
        />
        <meta
            property="twitter:title"
            content="Personal Goals for 2024"
        />
        <meta
            property="twitter:description"
            content="AI-powered tool to create personal goals. Smart, strategic, and simple to use. Elevate your productivity and start achieving today!"
        />
        <meta
            property="twitter:image"
            content="https://static.planmylife.app/assets/personalgoalsgenerator/social.png"
        />
      </Helmet>
      <Header />

      <main>
        <div className="container">
          <h1 className="mini-headline">Personal Goals with a little bit of AI</h1>
          <p className="headline">Make Your Year 2024 Worth It</p>
          <p className="main-paragraph">Personal goal setting is your life's roadmap. It turns dreams into plans. With goals, you're not just wandering; you're moving purposefully towards what matters to you.</p>

          <Questions />
        </div>
      </main>

      <footer id="footer" className="footer">
        <p><b>Try my other apps:</b></p>
        <div>
          <img
            className="footer__gratitude"
            src="https://static.planmylife.app/assets/personalgoalsgenerator/gratitudejournal.png"
            alt="Gratitude Journal app"
          />
          <div className="footer__download">
            <a href="https://apps.apple.com/us/app/grateful-moments/id6472909284" target="_blank">
              <img
                src="https://static.planmylife.app/assets/appstores/apple-app-store-logo.png?434"
                alt="Gratitude Journal app on App Store"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.planmylife.gratitudejournal" target="_blank">
              <img
                src="https://static.planmylife.app/assets/appstores/google-play-badge.png?434"
                alt="Gratitude Journal app on Play Store"
              />
            </a>
          </div>
        </div>

        <AboutMe />

        <span>Contact me: </span><a className="footer__link" id="email-link" href="mailto:">Contact Me</a>

        <p><a className="footer__link" href="https://planmylifeapps.notion.site/Terms-And-Conditions-c23b757ee3b846e0b56f7d566d603615" target="_blank">Terms and Conditions</a></p>
        <p><a className="footer__link" href="https://planmylifeapps.notion.site/Privacy-Policy-750f8e08f23f44b5aacac4af088f509e" target="_blank">Privacy Policy</a></p>
      </footer>
    </div>
  );
}

export default App;
