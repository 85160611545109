import './Smart.css';

type ComponentTypes = {
  specific: string,
  measurable: string,
  achievable: string,
  relevant: string,
  timeBound: string,
};

export const Smart = ({
  specific,
  measurable,
  achievable,
  relevant,
  timeBound,
}: ComponentTypes) => {
  return (
    <ul className="smart">
      <li><b>Specific: </b>{specific}</li>
      <li><b>Measurable: </b>{measurable}</li>
      <li><b>Achievable: </b>{achievable}</li>
      <li><b>Relevant: </b>{relevant}</li>
      <li><b>Time-bound: </b>{timeBound}</li>
    </ul>
  )
}