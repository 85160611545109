export type DataType = {
  title: string,
  answers: string[],
};

export const CATEGORIES: string[] = [
  'Career & Education',
  'Health & Wellness',
  'Financial Stability',
  'Personal Relationships',
  'Personal Development',
  'Leisure & Recreation',
  'Community & Contribution',
  'Life Milestones',
];

export const DATA_CAREER_ADVANCEMENTS: DataType = {
  title: 'Are there any career advancements (e.g., promotion, new skills) you aim to achieve this year?',
  answers: [
    'Promotion to a higher position',
    'Switching to a managerial or leadership role',
    'Transitioning to a different industry',
    'Gaining expertise in a high-demand skill',
    'Earning a professional certification',
    'Learning a new technology or software platform',
    'Expanding professional network by connecting with industry leaders and peers',
    'Starting a side business or consulting practice to explore entrepreneurial interests',
    'Improving public speaking or presentation skills to enhance communication',
    'Negotiating a better employment package, including salary, benefits, and work-life balance',
    'None of the above',
  ],
};

export const DATA_CAREER_EDUCATION_OBJECTIVES: DataType = {
  title: 'Do you have any educational objectives, such as enrolling in a course or obtaining a certification?',
  answers: [
    'Enrolling in an online course to learn a new programming language.',
    'Obtaining a project management certification like PMP or Agile.',
    'Completing a professional workshop on leadership or team building.',
    'Studying part-time for an MBA or other advanced degree related to their field.',
    'Attending a coding bootcamp to develop full-stack web development skills.',
    'Learning a new foreign language to enhance communication in a global market.',
    'Taking a creative writing class to improve storytelling abilities for content creation.',
    'Achieving certification in a specialized software tool used within their industry.',
    'Pursuing continuous education credits required to maintain professional licensure.',
    'Participating in an industry conference with educational sessions for professional development.',
    'None of the above',
  ],
}

export const DATA_HEALT_GOALS: DataType = {
  title: 'What are your health goals (e.g., lose weight, run a marathon)?',
  answers: [
    'Lose weight to achieve a specific target or healthy BMI.',
    'Run a half-marathon or a full marathon.',
    'Build muscle and increase overall strength.',
    'Improve cardiovascular health by engaging in regular aerobic exercise.',
    'Establish a consistent yoga practice to enhance flexibility and reduce stress.',
    'Eat a balanced diet with an emphasis on whole foods and proper nutrition.',
    'Drink more water daily and stay well-hydrated.',
    'Achieve a specified number of daily steps or active minutes.',
    'Cut down on sugar intake and reduce processed foods.',
    'Quit smoking or reduce alcohol consumption for better overall health.',
    'None of the above',
  ],
};

export const DATA_HEALT_WELLNESS: DataType = {
  title: 'Are there wellness practices (e.g., meditation, improved sleep) you wish to incorporate into your routine?',
  answers: [
    'Daily Meditation: Dedicate a set amount of time each day for mindfulness and meditation.',
    'Improved Sleep Hygiene: Establish a regular sleep schedule and create a bedtime ritual to enhance sleep quality.',
    'Regular Exercise: Incorporate a consistent exercise regimen tailored to personal fitness goals.',
    'Healthy Eating Habits: Focus on a balanced diet with nutrient-dense foods and appropriate portion sizes.',
    'Digital Detox: Set aside time each day or week to disconnect from electronic devices.',
    'Journaling: Keep a daily journal to reflect on thoughts, feelings, and personal growth.',
    'Deep Breathing Exercises: Use deep breathing techniques to reduce stress and anxiety.',
    'Gratitude Practice: Start or end your day by listing things you’re grateful for.',
    'Nature Walks: Spend time outdoors regularly to connect with nature and get fresh air.',
    'Hydrotherapy: Practice hot/cold showers or baths to relax muscles and boost circulation.',
    'None of the above',
  ],
};

export const DATA_FINANCE_GOALS: DataType = {
  title: 'Do you have specific financial goals (e.g., saving a certain amount, reducing debt)?',
  answers: [
    'Save a Specific Amount: Set a target savings goal, such as saving $10,000 by year’s end.',
    'Reduce Credit Card Debt: Aim to pay off or significantly reduce your credit card debt.',
    'Emergency Fund: Build an emergency fund covering 3-6 months of living expenses.',
    'Retirement Savings: Increase contributions to a retirement account like a 401(k) or an IRA.',
    'Investment Portfolio: Create or diversify an investment portfolio for long-term growth.',
    'Down Payment for Home: Save for a down payment on a house or real estate investment.',
    'Education Fund: Start or contribute more to a college savings plan for yourself or a family member.',
    'Pay Off Student Loans: Develop a plan to pay down or pay off student loan debt.',
    'Improve Credit Score: Take action to improve your credit score to a targeted level.',
    'Budgeting: Implement a strict monthly budget to better control and track spending.',
    'None of the above',
  ],
};

export const DATA_FINANCE_INVESTMENTS: DataType = {
  title: 'Are there investments or financial planning activities you want to undertake?',
  answers: [
    'Stock Market Investing: Research and invest in individual stocks or index funds.',
    'Real Estate Investment: Look into purchasing rental properties or real estate investment trusts (REITs).',
    'Retirement Planning: Set up or revise a retirement savings plan, such as an IRA or 401(k).',
    'Robo-Advisor: Utilize a robo-advisor for automated investment management based on your risk tolerance.',
    'Peer-to-Peer Lending: Invest in peer-to-peer lending platforms for potential higher returns.',
    'Diversify Portfolio: Assess and diversify your investment portfolio across different asset classes.',
    'Tax Planning: Engage in tax planning to optimize tax efficiency for your investments and income.',
    "Education Fund: Establish a 529 plan or other education savings account for your children's future.",
    'Emergency Fund: Create or increase an emergency fund to provide financial cushion.',
    'Debt Management Plan: Develop a plan to prioritize and accelerate the repayment of high-interest debt.',
    'None of the above',
  ],
};

export const DATA_PERSONAL_RELATIONSHIPS: DataType = {
  title: 'Are you looking to improve or change any relationships (e.g., family bonds, making new friends)?',
  answers: [
    'Strengthen communication with a partner or spouse through regular check-ins and date nights.',
    'Reconnect with old friends with whom contact has been lost over the years.',
    'Seek reconciliation and forgiveness in strained or broken family relationships.',
    'Build a stronger bond with children through quality time and shared activities.',
    'Expand social circles by joining clubs or groups that align with personal interests.',
    'Improve professional relationships by networking and collaborating more with colleagues.',
    'Cultivate new friendships by participating in community events or volunteering.',
    'Set boundaries in toxic relationships to create a healthier dynamic.',
    'Provide more support and understanding to a friend or family member in need.',
    'Engage in active listening and show more empathy in daily interactions with others.',
    'None of the above',
  ],
};

export const DATA_PERSONAL_EVENT: DataType = {
  title: 'Is there a significant relationship event you are working towards (e.g., marriage, reconciliation)?',
  answers: [
    'Planning a marriage proposal or organizing a wedding.',
    'Celebrating a milestone anniversary with a special event or trip.',
    'Arranging a family reunion to reconnect with extended relatives.',
    'Preparing for the birth of a child or adoption process.',
    'Organizing a vow renewal ceremony with a spouse or partner.',
    'Coordinating a getaway to rekindle romance in a long-term relationship.',
    'Working on a therapeutic process for reconciliation with an estranged family member.',
    'Hosting an engagement party or bridal shower for a close friend or yourself.',
    'Planning a significant birthday or celebration for a loved one.',
    'Arranging a heartfelt gesture or surprise to show appreciation for a friend or partner.',
    'None of the above',
  ],
};

export const DATA_PERSONAL_DEVELOPMENT: DataType = {
  title: 'What are your self-improvement priorities (e.g., learning a language, reading more)?',
  answers: [
    'Mastering a new language to enhance communication and cultural understanding.',
    'Reading a set number of books each month for personal development and knowledge growth.',
    'Taking up a new hobby like painting, playing an instrument, or gardening.',
    'Developing better organizational and time-management skills.',
    'Practicing public speaking to boost confidence and presentation abilities.',
    'Learning to cook healthful meals for better nutrition and culinary skills.',
    'Engaging in daily meditation or mindfulness practices to reduce stress.',
    'Attending workshops or seminars to improve leadership and teamwork skills.',
    'Taking online courses to gain expertise in a specific area of interest or professional field.',
    'Focusing on emotional intelligence by working on empathy and relationship-building skills.',
    'None of the above',
  ],
};

export const DATA_PERSONAL_HABITS: DataType = {
  title: 'Are there any personal habits you wish to develop or break?',
  answers: [
    'Develop: Establish a morning routine that includes exercise and a healthy breakfast.',
    'Break: Overcome procrastination by implementing productivity techniques.',
    'Develop: Practice regular journaling or reflection for mental clarity and goal tracking.',
    'Break: Cut down on social media usage to reduce screen time and increase presence.',
    'Develop: Drink more water throughout the day to stay hydrated and improve overall health.',
    'Break: Stop smoking or reduce nicotine intake for better long-term health.',
    'Develop: Adopt a habit of going to bed and waking up at the same time each day.',
    'Break: Eliminate junk food and mindless snacking, replacing them with healthier options.',
    'Develop: Incorporate mindfulness or meditation into daily life to manage stress better.',
    'Break: Quit negative self-talk and replace it with positive affirmations and self-compassion.',
    'None of the above',
  ],
};

export const DATA_LEISURE_HOBBIES: DataType = {
  title: 'Are there any hobbies or recreational activities (e.g., travel, arts) you plan to start or continue?',
  answers: [
    'Photography: Begin a photography project or enhance skills in photo editing.',
    'Painting/Drawing: Start painting or drawing classes, or experiment with different mediums.',
    'Travel: Plan a domestic or international trip to explore new cultures and environments.',
    'Hiking/Outdoors: Regularly engage in hiking or camping to connect with nature.',
    'Music: Learn to play a musical instrument or join a community music group.',
    'Writing: Start a blog, write short stories, or work on a novel.',
    'Cycling: Take up cycling as a form of exercise or join a local cycling club.',
    'Cooking/Baking: Explore new cuisines, take cooking classes, or start a baking challenge.',
    'Dance: Enroll in dance lessons or attend dance fitness classes like Zumba.',
    'Gardening: Develop a green thumb by starting a garden or joining a community gardening project.',
    'None of the above',
  ],
};

export const DATA_LEISURE_EXPERIENCE: DataType = {
  title: "Is there an experience or challenge you're aiming for (e.g., a trip abroad, a creative project)?",
  answers: [
    "Travel Abroad: Embark on a solo travel adventure to a country you've never visited.",
    'Creative Project: Launch a personal creative venture, such as writing a book or starting a YouTube channel.',
    'Athletic Event: Train for and participate in a marathon, triathlon, or obstacle course race.',
    'Volunteering: Commit to a long-term volunteer project that aligns with your passions.',
    'Public Speaking: Enter a public speaking competition or deliver a TEDx talk.',
    'Skill Mastery: Achieve a new level of proficiency in a skill, such as mastering a martial art or a musical instrument.',
    'Educational Achievement: Complete a degree or professional certification that requires dedication and study.',
    'Wellness Retreat: Attend a wellness or meditation retreat for personal growth and introspection.',
    'Artistic Performance: Prepare and perform in a local theater production or music recital.',
    'Entrepreneurship: Start a new business or launch an innovative product or service in the market.',
    'None of the above',
  ],
};

export const DATA_COMMUNITY_GOALS: DataType = {
  title: 'Do you have any goals related to giving back to the community or volunteering?',
  answers: [
    'Mentor a student or young professional in your field of expertise.',
    'Participate in building homes for those in need with an organization like Habitat for Humanity.',
    'Regularly volunteer at a local food bank or soup kitchen.',
    'Start a community garden or join a local environmental cleanup project.',
    "Organize or join fundraising events for a cause you're passionate about.",
    "Offer pro bono services to nonprofits or community groups in need.",
    'Coach a youth sports team or lead an after-school program.',
    'Engage in advocacy work for social issues or policy changes.',
    'Volunteer at an animal shelter or foster pets in need.',
    'Lead or participate in workshops and educational programs for community development.',
    'None of the above',
  ],
};

export const DATA_COMMUNITY_ISSUES: DataType = {
  title: "Are there social issues or causes you would like to be more involved with?",
  answers: [
    'Climate Change and Environmental Conservation',
    'Animal Welfare and Rights',
    'Homelessness and Housing Insecurity',
    'Mental Health Advocacy and Awareness',
    'Education Equity and Literacy Programs',
    'Racial Justice and Equality Movements',
    'Food Security and Access to Nutritious Meals',
    'Healthcare Access and Affordability',
    "Gender Equality and Women's Rights",
    'Refugee Support and Immigrant Rights',
    'None of the above',
  ],
};

export const DATA_LIFE_MILESTONES: DataType = {
  title: 'Are there any key life milestones (e.g., buying a house, starting a family) you aim to achieve?',
  answers: [
    'Buying your first home or investing in real estate.',
    'Starting a family or having another child.',
    'Getting married or celebrating a significant wedding anniversary.',
    'Relocating to a new city or country for a fresh start.',
    'Beginning a new career or launching a personal business.',
    'Completing a significant academic degree or course of study.',
    'Celebrating a milestone birthday or organizing a memorable event.',
    'Retiring from work or transitioning into a new phase of life.',
    'Achieving a major personal fitness goal, like completing a marathon.',
    'Paying off a significant debt, like a student loan or mortgage.',
    'None of the above',
  ],
};

export const DATA_LIFE_ISSUES: DataType = {
  title: "Do you wish to make any major lifestyle changes this year?",
  answers: [
    'Transitioning to a plant-based diet for health and ethical reasons.',
    'Adopting a minimalist lifestyle to reduce clutter and focus on essentials.',
    'Incorporating a daily fitness routine to improve physical health.',
    'Implementing a zero-waste lifestyle to reduce environmental impact.',
    'Shifting to a more sustainable mode of transportation, like cycling or public transit.',
    'Pursuing a digital nomad lifestyle to travel while working remotely.',
    'Practicing regular digital detoxes for better mental health and productivity.',
    'Moving to the countryside or a smaller town for a quieter, slower-paced life.',
    'Working towards financial independence to reduce reliance on a traditional 9-to-5 job.',
    'Engaging in lifelong learning, dedicating time regularly to acquire new knowledge and skills.',
    'None of the above',
  ],
};