import { useState } from 'react';
import { Input, Space, Button, Spin } from 'antd';
import { httpsCallable } from 'firebase/functions';
import { LoadingOutlined } from '@ant-design/icons';

import { functions } from '../../firebase';

import './ModalContent.css';

type ComponentProps = {
  isFetching: boolean,
  hasError: string,
};

export const ModalContent = ({
  isFetching,
  hasError,
}: ComponentProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const validateEmail = (email: string) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const submitHandler = async () => {
    const isEmailValid = validateEmail(inputValue);

    if (!isEmailValid) {
      setError(true);
    } else {
      await addUserToNewsletterDB();
    }
  };

  const addUserToNewsletterDB = async () => {
    await setIsSaving(true)
    const addUserToEmailList = httpsCallable(functions, 'addUserToEmailList');
    const response = await addUserToEmailList({ email: inputValue });

    await setIsSaving(false);
    await setIsSaved(true);
  };

  if (isFetching) {
    return (
      <div className="modalcontent__fetching">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        <p><b>Hang tight - We're teaching the AI to hustle.</b></p>
        <p>It's spinning up some genius goals for you — might take a minute or two!</p>
      </div>
    );
  }

  if (hasError !== '') {
    return (
      <div className="modalcontent__fetching">
        <p className="modelcontent__error">{hasError}</p>
      </div>
    );
  }

  return (
    <div className="modalcontent">
      <h2>Join The Goal-Setting Revolution!</h2>
      <p>My innovative goal-setting app is on the horizon, and you're invited to be a part of the journey.</p>
      <p>Subscribe for exclusive updates:</p>
      <ul>
        <li>Early glimpses of the app's features.</li>
        <li>First dibs on beta testing opportunities.</li>
        <li>Practical goal-setting strategies direct to your inbox.</li>
        <li>Access to subscriber-only discounts at launch.</li>
      </ul>
      <p>Hit subscribe and stay ahead of the curve with our journey from concept to reality.</p>
      <Space.Compact style={{width: '100%' }}>
        {(!isSaving && !isSaved) &&
        <>
          <Input
            size="large"
            placeholder="Your email address"
            onChange={inputChangeHandler}
            className="modelcontent__input"
          />
          <Button
            size="large"
            type="primary"
            className="modelcontent__input"
            onClick={submitHandler}
          >Subscribe</Button>
        </>
        }
        {isSaving && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        {isSaved && <p className="modelcontent__success">Success!</p>}
      </Space.Compact>
      <Space.Compact style={{width: '100%' }}>
        {error && <p className="modelcontent__error">Oops! It looks like there might be a typo in your email address. Please double-check it and try again. We want to make sure we can reach you!</p>}
      </Space.Compact>
    </div>
  )
};